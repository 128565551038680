<template>
  <div v-if="signature">
    <h4>W9 Form</h4>
  
    <!-- Download button -->
    <a :href="pdfBlobUrl" download="blessedfms_w9_form.pdf">blessedfms_w9_form.pdf<br>
      <button class="btn btn-warning m-2">Download PDF</button>
    </a>
  
    <!-- Modal for PDF preview -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <button @click="closeModal" class="btn btn-danger">Close</button>
        <h3>PDF Preview</h3>
        <iframe :src="pdfBlobUrl" width="100%" height="600px"></iframe>
      </div>
    </div>

    <!-- Button to open the modal for viewing -->
    <button class="btn btn-info" v-if="pdfBlobUrl && !showModal" @click="showModal = true">View PDF</button>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      pdfBlobUrl: null, // URL for the PDF blob
      showModal: false, // To control the modal visibility
      signature: false,
    };
  },
  mounted() {
    this.fetchPdf(); // Automatically fetch the PDF when the component is loaded
  },
  methods: {
    async fetchPdf() {
      try {
        const token = localStorage.getItem('token');
            const acctData = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_account_status`,[], {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          this.signature = acctData.data.data.signature > 0;
          if(acctData.data.data.signature <=0)
          {
            return  Swal.fire({
                  title: "You need to complete your Signature to access this feature",
                  icon: "error",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton:true,
                  focusConfirm: false,
          })}
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_w9_form`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              "Cache-Control": "no-cache",
            },
            responseType: "blob", // Expecting a binary response (PDF)
          }
        );
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        this.pdfBlobUrl = URL.createObjectURL(pdfBlob);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    },
    closeModal() {
      this.showModal = false; // Close the modal
    },
  },
};
</script>

<style scoped>
/* Styles for modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
}

button {
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

button:hover {
  background-color: #ddd;
}
</style>
