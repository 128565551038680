<template>
  <div class="bank-account-registration">
    <h3>{{ isEditing ? "Update Bank Account" : "Register Bank Account" }}</h3>
    <form @submit.prevent="submitBankDetails">
      <div class="form-group">
        <label for="accountType">Account Type:</label>
        <select v-model="form.account_type" id="accountType" required>
          <option value="1">Savings</option>
          <option value="2">Checking</option>
        </select>
      </div>

      <div class="form-group">
        <label for="bankName">Bank Name:</label>
        <input
          type="text"
          id="bankName"
          v-model="form.bank_name"
          placeholder="Enter bank name"
          required
        />
      </div>

      <div class="form-group">
        <label for="routingNumber">Routing Number:</label>
        <input
          type="number"
          id="routingNumber"
          v-model="form.routing_number"
          placeholder="Enter routing number"
          required
        />
      </div>

      <div class="form-group">
        <label for="accountNumber">Account Number:</label>
        <input
          type="number"
          id="accountNumber"
          v-model="form.account_number"
          placeholder="Enter account number"
          required
        />
      </div>
      <button type="submit">{{ isEditing ? "Update" : "Submit" }}</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BankAccountRegistration",
  data() {
    return {
      isEditing: false, // Indicates if we are editing an existing account
      form: {
        id: null, // Used for updating records
        account_type: 1,
        bank_name: "",
        routing_number: "",
        account_number: "",
        paystub_copy: false,
      },
    };
  },
  methods: {
  async fetchBankDetails() {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_bank_account`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.data) {
        this.isEditing = true;
        this.form = {
          id: response.data.data.id,
          account_type: response.data.data.account_type,
          bank_name: response.data.data.bank_name,
          routing_number: response.data.data.routing_number,
          account_number: response.data.data.account_number,
          paystub_copy: response.data.data.paystub_copy,
        };
      }
    } catch (error) {
      console.warn("No existing bank account found. Ready for registration.");
    }
  },

  async submitBankDetails() {
    try {
      const token = localStorage.getItem("token");
      const url = this.isEditing && this.form.id
        ? `${process.env.VUE_APP_BACKEND_DOMAIN}/api/update_bank_account/${this.form.id}`
        : `${process.env.VUE_APP_BACKEND_DOMAIN}/api/update_bank_account`;

      const method = this.isEditing && this.form.id ? "put" : "post";

      await axios({
        method,
        url,
        data: this.form,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      alert(
        this.isEditing
          ? "Bank account updated successfully."
          : "Bank account registered successfully."
      );
    } catch (error) {
      console.error("Error saving bank account details:", error.response);
      alert(
        error.response?.data?.message || "Failed to save bank account details."
      );
    }
  },
},

  mounted() {
    this.fetchBankDetails();
  },
};
</script>

<style scoped>
.bank-account-registration {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select,
button {
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

button {
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}
</style>
