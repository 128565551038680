<template>
  <div class="row">
      <div class="col-lg-12 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Messages</p>
                  <p class="mb-1 pt-2 text-bold">This message will be sent to your patient.</p>
                  <h5 class="font-weight-bolder">Send Us a Message</h5>
                  <form action="">
                    <soft-input
                      id="subject"
                      type="text"
                      placeholder="Subject"
                    />
                    <soft-text-area
                      id="message"
                      type="text"
                      name="specify"
                      size="lg"
                      placeholder="Please specify the details of your concern."
                    />
                    <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="info"
                        @click="sendMail"
                        type="button"
                        >Send
                      </soft-button>


                  </form>
                  
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftTextArea from "@/components/SoftTextarea.vue";
import SoftButton from "@/components/SoftButton.vue";
import Swal from 'sweetalert2';
import axios from "axios";
import $ from 'jquery';
export default {
  components: {

    SoftInput,
    SoftTextArea,
    SoftButton

  },
  data() {
    return {
      formData: {
        subject: "",
        message: "",
      },
    };
  },
  methods:{
   async sendMail()
    {
      
      const token =  localStorage.getItem('token');
      const formData = {
        subject: $('#subject').val(),
        message: $('#message').val(),
      };
      try {
            const response = await Swal.fire({
              title: 'Are you sure you want to send this message?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, send it!',
              allowOutsideClick: false,
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                try {
                  const result = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/send_email_vend`, formData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  });

                  if (result.data.status === 200) {
                    return result.data;
                  } else {
                    throw new Error(result.data.message || 'Failed to send email');
                  }
                } catch (error) {
                  Swal.showValidationMessage(error.message);
                }
              },
            });

            if (response.value) {
              Swal.fire({ title: response.value.message, icon: 'success' });
              setTimeout(() => {
               
                location.reload();
              }, 2000);
            } else if (response.dismiss === Swal.DismissReason.cancel) {
              console.log('Email sending cancelled');
            }
          } catch (error) {
            // Handle unexpected errors
            console.error(error);
          }

    }
  }

};
</script>
