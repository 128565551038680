<template>
    <div class="container-fluid">
      <div
        class="mt-2 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-2 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="signature-generator">
            <h3>Generate Your Signature</h3>
  
            <!-- Toggle between Draw and Type Mode -->
            <div class="signature-mode-toggle">
              <label>
                <input type="radio" v-model="signatureMode" value="type" /> Type Signature
              </label>
              <label>
                <input type="radio" v-model="signatureMode" value="draw" /> Draw Signature
              </label>
            </div>
  
            <!-- Signature Style Customization -->
  
            <!-- Type Signature -->
            <div v-if="signatureMode === 'type'">
              <div class="style-options">
                <h4>Customize Style</h4>
  
                <!-- Font Family -->
                <div>
                  <label for="fontFamily">Font Family:</label>
                  <select v-model="signatureStyle.fontFamily" id="fontFamily">
                    <option value="Alex Brush, cursive">Alex Brush</option>
                    <option value="Mr Dafoe, cursive">Mr Dafoe</option>
                    <option value="MrDeHaviland, cursive">MrDeHaviland</option>
                    <option value="PWSimpleScript, cursive">PWSimpleScript</option>
                  </select>
                </div>
  
                <!-- Font Size (Small, Medium, Large) -->
                <div>
                  <label for="fontSize">Font Size:</label>
                  <select v-model="signatureStyle.fontSize" id="fontSize">
                    <option value="36px">Small</option>
                    <option value="48px">Medium</option>
                    <option value="72px">Large</option>
                  </select>
                </div>
              </div>
              <div class="signature-box">
                <span :style="signatureStyle">{{ signatureText }}</span>
              </div>
              <div class="input-box">
                <label for="name">Enter Your Name:</label>
                <input v-model="name" id="name" type="text" placeholder="Type your name" @input="generateSignature" />
              </div>
              <!-- Button for Saving Text Signature -->
              <button v-if="signatureText" @click="saveTypedSignature">Save Typed Signature</button>
            </div>
  
            <!-- Draw Signature -->
            <div v-if="signatureMode === 'draw'" class="draw-box">
              <canvas
                ref="canvas"
                id="signatureCanvas"
                :width="canvasWidth"
                :height="canvasHeight"
                @mousedown="startDrawing"
                @mousemove="draw"
                @mouseup="stopDrawing"
                @mouseout="stopDrawing"
                class="canvas-signature"
              ></canvas>
              <button @click="clearCanvas">Clear</button>
              <!-- Button for Saving Drawn Signature -->
              <button v-if="isSignatureDrawn" @click="saveDrawnSignature">Save Drawn Signature</button>
            </div>
  
            <!-- Display Drawn Signature Image -->
            <div v-if="signatureImage">
              <img :src="signatureImage" alt="Drawn Signature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import swal from 'sweetalert2';

  export default {
    name: "SignatureGenerator",
    data() {
      return {
        name: '',
        signature: '',
        signatureText: '',
        signatureStyle: {
          fontFamily: "Alex Brush, cursive",
          fontSize: "40px", 
          fontWeight: "200", 
          color: "#000000", 
        },
        signatureMode: 'type',
        canvasWidth: 400,
        canvasHeight: 200,
        isDrawing: false,
        signatureImage: null,
        isSignatureDrawn: false, 
      };
    },
    methods: {
      generateSignature() {
        this.signatureText = this.name ? this.name : '';
      },
  
      // Draw Mode Methods
      startDrawing(event) {
        this.isDrawing = true;
        const ctx = this.$refs.canvas.getContext("2d");
        ctx.beginPath();
        ctx.moveTo(event.offsetX, event.offsetY);
      },
      draw(event) {
        if (!this.isDrawing) return;
        const ctx = this.$refs.canvas.getContext("2d");
        ctx.lineTo(event.offsetX, event.offsetY);
        ctx.stroke();
      },
      stopDrawing() {
        if (this.isDrawing) {
          this.isDrawing = false;
          const ctx = this.$refs.canvas.getContext("2d");
          ctx.closePath();
          this.isSignatureDrawn = true; // Set flag after drawing is done
        }
      },
      clearCanvas() {
        const ctx = this.$refs.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.isSignatureDrawn = false; // Reset flag if cleared
      },
      async saveDrawnSignature() {
        const canvas = this.$refs.canvas;
        this.signatureImage = canvas.toDataURL("image/png");
        const signatureImageFile = this.dataURLToFile(this.signatureImage, 'signature.png');
        const signatureData = new FormData();
        signatureData.append('signature', signatureImageFile);
        signatureData.append('style', JSON.stringify(this.signatureStyle));
        signatureData.append('type', 'draw');
       
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/update_account_signature_draw`, signatureData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },        
        })
        swal.fire({
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        })

      },
      dataURLToFile(dataURL, filename) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];  // Extract MIME type
        const bstr = atob(arr[1]);  // Decode base64 string
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);  // Convert to byte array
        }
        return new File([u8arr], filename, { type: mime });
        },
  
      // Save Typed Signature
      async saveTypedSignature() {
        const signatureData = {
          signature: this.signatureText,
          style: this.signatureStyle,
          type: 'typed',
        }
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/update_account_signature`, signatureData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        swal.fire({
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      },
      async fetchAccountSignature() {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_account_signature`, [], {
                headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                },
            });

            console.log(response.data.data.signature);
            
            if (response.data.data.type === "drawn") {
                this.convertImageToCanvas(response.data.data.signature_url);
            }

            // Make sure signatureText is populated
            this.signatureText = response.data.data.signature || '';

            // Assign name and signatureStyle with default values if necessary
            this.name = response.data.data.signature || '';
            
            // Ensure that signatureStyle has all the necessary properties
            this.signatureStyle = {
                fontFamily: response.data.data.style?.fontFamily || this.signatureStyle.fontFamily,
                fontSize: response.data.data.style?.fontSize || this.signatureStyle.fontSize,
                fontWeight: response.data.data.style?.fontWeight || this.signatureStyle.fontWeight,
                color: response.data.data.style?.color || this.signatureStyle.color,
            };
            
            console.log(this.signatureStyle);
            },

    convertImageToCanvas(url) {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Important if the image is from a different domain (like S3)

        img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL("image/png");

        
        this.signatureImage = imageData;
        };
        img.src = url;
    }
    },
    mounted() {
      this.fetchAccountSignature();
    },
    
  };
  </script>
  
  <style scoped>
  .signature-generator {
    text-align: center;
    margin-top: 20px;
  }
  
  .signature-mode-toggle {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .signature-mode-toggle label {
    margin-right: 15px;
    font-size: 16px;
  }
  
  .signature-box {
    margin: 20px auto;
    padding: 10px;
  }
  
  .signature-box span {
    display: block;
  }
  
  .input-box {
    margin-top: 20px;
  }
  
  .input-box input {
    padding: 10px;
    font-size: 16px;
    width: 80%;
    max-width: 300px;
    border: 2px solid #ccc;
    border-radius: 4px;
  }
  
  .input-box label {
    display: block;
    margin-bottom: 5px;
  }
  
  .style-options {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .style-options div {
    margin-bottom: 15px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #286ea7;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  .canvas-signature {
    border: 2px solid #ccc;
  }
  
  img {
    margin-top: 20px;
    max-width: 300px;
  }
  
  @media (max-width: 600px) {
    .signature-generator {
      padding: 10px;
    }
    .input-box input {
      width: 100%;
    }
  }
  </style>
  