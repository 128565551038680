<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1 text-uppercase"> {{ firstName }} {{ lastName }}  </h5>
              <p class="mb-0 text-sm font-weight-bold text-uppercase">{{ companyName }}</p>
            </div>
          </div>
          <div
            class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
          >
          <div class="text-end mb-2">
            <soft-button color="info" size="lg"  @click="btn_time_entry">
                Add Invoice
            </soft-button>
        </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
        <div class="row">
      <div class="col-12">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7"
              >
                Description
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
                Price
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
                Client's Name
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder opacity-7 ps-2"
              >
                Document Uploaded
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder text-center opacity-7 ps-2"
              >
                Receipt File
              </th>
              <th
                class="text-uppercase text-secondary text-medium font-weight-bolder text-center opacity-7 ps-2"
              >
                Status
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td>
                <div class="d-flex px-2">
                  <div>
                  
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-sm">{{ invoice.description  }} </h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-lg font-weight-bold mb-0">{{ invoice.invoice_price  }} </p>
              </td>
              <td>
                <p class="text-lg font-weight-bold mb-0">{{ invoice.client_name  }} </p>
              </td>
              <td>
                <button @click="openFileSwal(invoice.invoice_file, getFileNameFromUrl(invoice.invoice_file))" 
                class="btn btn-primary"
                >
                  {{ getFileNameFromUrl(invoice.invoice_file)}}
                  
                </button>
              </td>
              <td>
                
                <div v-if="invoice.reciept_file" >
                  <button @click="download_invoice(invoice.reciept_file, getFileNameFromUrl(invoice.reciept_file))" 
                class="btn btn-success"
                >
                  Download
                  
                </button>
                </div>
                <div v-else >
                  <button  
                class="btn btn-info"
                >
                  Waiting for admin's reciept
                  
                </button>
                </div>
              </td>
              <td class="align-middle text-center ">
                <div v-if="invoice.is_complete == 0" >
                  <span class="text-lg font-weight-bold">Pending</span>
                </div>
                <div v-else-if="invoice.is_complete == 1" >
                  <span class="text-lg font-weight-bold">Approved</span>
                </div>
                <div v-else>
                  <span class="text-lg font-weight-bold">Declined</span>
                </div>
              </td>
              <td class="align-middle">
              
              </td>
            </tr>
          </tbody>
        </table>
    
    </div>
    
  </div>
   
    </div>
    <modal :is-open="isModalOpen" @close="closeModal"/>
  </template>
  
  <script>
  import Modal from "@/views/components/UploadInvoiceModal.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import $ from 'jquery';
  export default {
    name: "Invoice Page",
    components: {
    Modal,
    SoftButton
  },
  data()
        {
           return {
            isModalOpen: false,
            invoices:[]
           }
        },
    computed: {
      firstName() {
        return localStorage.getItem('first_name') || 'Welcome';
      },
      lastName() {
        return localStorage.getItem('last_name') || 'Here';
      },
      companyName() {
        return localStorage.getItem('company_name') || 'Blessed FMS';
      }
    },
    methods: {
        async btn_time_entry()
        {
          const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_account_status`,[], {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          const signature = response.data.data.signature > 0 ? "" : "Signature";
          const payment = response.data.data.payment > 0 ? "" : "Payment Details";
          if(response.data.data.signature <=0 || response.data.data.payment <=0)
          {
            return  Swal.fire({
                  title: "You need to complete your "+signature+", "+payment+" to access this feature",
                  icon: "error",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton:true,
                  focusConfirm: false,
                });
          }
        this.isModalOpen = true;
        },
        download_invoice(url, fileName)
        {
          const component = this;
          $.ajax({
              url: url,
              type: 'GET',
              xhrFields: {
                  responseType: 'blob' 
              },
              success: function(data) {
                component.downloadBlob(data,fileName);
              },
              error: function(jqXHR, textStatus, errorThrown) {
                  console.error('Error fetching image:', errorThrown);
              }
          });
        },
        downloadBlob(blob, fileName)
        {
          const url = window.URL.createObjectURL(blob);  
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;  
          document.body.appendChild(a);  
          a.click();  
          document.body.removeChild(a);  
          window.URL.revokeObjectURL(url);

        },
        closeModal()
        {
        this.isModalOpen = false;
        },
        getFileNameFromUrl(url) {
            const parts = url.split('/');
            return parts[parts.length - 1];
          },
        openFileSwal(file_data, filename) {
                Swal.fire({
                  title: filename,
                  html: this.getHtmlContent(file_data, filename),
                  showCloseButton: true,
                  showCancelButton: false,
                  showConfirmButton:false,
                  focusConfirm: false,
                });
          },
          getHtmlContent(file_data, file_name) {
              let content = '';
              // var encodedFileName = encodeURIComponent(file_name);
       
              if (this.isImage(file_name)) 
              {
                content += `<img src="${file_data}" style="max-width: 100%; max-height: 100%;">`;
              
              } 
              else if (this.isExcel(file_name)) 
              {
                content += `Please Download The file to view: <a class="bg-teal-500" href="${file_data}" download="${file_name}">Click here to download the file</a>`;
              } 
              else 
              {
                content += "Unsupported file type";
              }

              return content;
            },
            
            isImage(fileName) {
              const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".pdf"];
              return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
            },
            isExcel(fileName) {
              // Check if the MIME type indicates an Excel file
              return fileName.toLowerCase().endsWith(".xlsx");
            },
            
        async fetch_invoices()
        {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_invoices_vendor`, this.formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          this.invoices = response.data.data;
          console.log(this.invoices);
        }
    },
    mounted(){
        this.fetch_invoices()
    }

  };
  </script>
  
  