<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <router-link :to="{ name: 'Profile' }" class="text-decoration-none">
          <mini-statistics-card
          title="Details"
            value="Account"
            :percentage="{
              value: '+505%',
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-check-bold',
              background: iconSuccessBackground,
            }"
            direction-reverse
          />
        </router-link>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <router-link :to="{ name: 'Payment Dtails' }" class="text-decoration-none">
          <mini-statistics-card
            title="Details"
            value="Payment"
            :percentage="{
              value: '+3%',
              color: 'text-success',
            }"
            :icon="{
              component: payment ? 'ni ni-check-bold' : 'fa fa-exclamation',
              background: payment ? iconSuccessBackground : iconErrorBackground ,
            }"
            direction-reverse
          />
        </router-link>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <router-link :to="{ name: 'Signature' }" class="text-decoration-none">
          <mini-statistics-card
            title="Account"
            value="Signature"
            :percentage="{
              value: '-2%',
              color: 'text-danger',
            }"
            :icon="{
              component: signature ? 'ni ni-check-bold' : 'fa fa-exclamation',
              background: signature ? iconSuccessBackground : iconErrorBackground  ,
            }"
            direction-reverse
          />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import axios from 'axios';
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconSuccessBackground: "bg-gradient-success",
      iconErrorBackground: "bg-gradient-danger",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      vendorAccountDetails: [],
      signature:false,
      payment:false

    };
  },
  components: {
    MiniStatisticsCard,
  },
  methods: {
    async fetchAccountStatus() {
      const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_account_status`,[], {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          this.signature = response.data.data.signature > 0;
          this.payment = response.data.data.payment > 0;
         
    }
  },
  mounted() {
    this.fetchAccountStatus();
  },
};
</script>
