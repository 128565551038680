<template>
     <div class="container-fluid">
    <!-- Existing Profile View -->
   
  
    <div>
      <h3>Search for a Client</h3>
      <form @submit.prevent="searchClient" class="d-flex align-items-center">
        <div class="form-group me-2">
            <label for="first_name" class="form-label">First Name</label>
            <input
            id="first_name"
            v-model="searchCriteria.first_name"
            class="form-control"
            required
            />
        </div>
        <div class="form-group me-2">
            <label for="last_name" class="form-label">Last Name</label>
            <input
            id="last_name"
            v-model="searchCriteria.last_name"
            class="form-control"
            required
            />
        </div>
        <button type="submit" class="btn btn-primary mt-3 align-self-end">Search</button>
        </form>

  
      <div v-if="clients.length > 0" class="mt-4">
        <h4>Search Results</h4>
        <ul class="list-group">
          <li
            v-for="client in clients"
            :key="client.id"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span>{{ client.first_name }} {{ client.last_name }}</span>
            <button
              class="btn btn-success btn-sm"
              @click="enrollClient(client.id)"
            >
              Enroll
            </button>
          </li>
        </ul>
      </div>
      <p v-else-if="searchPerformed">No results found.</p>
  
      <div class="mt-5">
        <h4>Enrolled Accounts</h4>
        <ul v-if="enrolledAccounts.length > 0" class="list-group">
          <li
            v-for="account in enrolledAccounts"
            :key="account.id"
            class="list-group-item"
          >
            {{ account.first_name }} {{ account.last_name }}
          </li>
        </ul>
        <p v-else>No enrolled accounts found.</p>
      </div>
    </div>
  
</div>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from 'sweetalert2';
  export default {
    name: "ClientSearch",
    data() {
      return {
        searchCriteria: {
          first_name: "",
          last_name: "",
        },
        clients: [],
        enrolledAccounts: [],
        searchPerformed: false,
      };
    },
    methods: {
      async searchClient() {
        this.searchPerformed = false;
        try {
          const token = localStorage.getItem("token");
          const response = await axios.post(
            `${process.env.VUE_APP_BACKEND_DOMAIN}/api/search_client`,
            this.searchCriteria,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.clients = response.data.clients;
          this.searchPerformed = true;
        } catch (error) {
          console.error("Error searching for client:", error);
          alert("Failed to search for clients. Please try again.");
        }
      },
      async enrollClient(clientId) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.post(
            `${process.env.VUE_APP_BACKEND_DOMAIN}/api/client_enrollment_to_vendor`,
            { client_id: clientId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.fetchEnrolledAccounts();
          this.searchClient();
          return  Swal.fire({
                  title: response.data.message,
                  icon: "error",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton:true,
                  focusConfirm: false,
                });
        } catch (error) {
          if (error.response && error.response.data) {
            alert(`Error: ${error.response.data.message}`);
          } else {
            alert("An unexpected error occurred.");
          }
        }
      },
      async fetchEnrolledAccounts() {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_enrolled_accounts`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.enrolledAccounts = response.data.accounts;
        } catch (error) {
          console.error("Error fetching enrolled accounts:", error);
          alert("Failed to fetch enrolled accounts. Please try again.");
        }
      },
    },
    mounted() {
      this.fetchEnrolledAccounts(); // Load enrolled accounts when the component is mounted
    },
  };
  </script>
  