<template>
  <div class="container-fluid">
    <!-- Existing Profile View -->
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto"></div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1 text-uppercase">
              {{ firstName }} {{ lastName }}
            </h5>
            <p class="mb-0 text-sm font-weight-bold text-uppercase">
              {{ companyName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Profile Info and Edit Form -->
  <div class="py-4 container-fluid">
    <div class="mt-3 row d-flex justify-content-center align-items-center">
      <div class="mt-4 col-12 col-md-8 col-xl-8 mt-md-0">
        <!-- Toggle between view/edit -->
        <div v-if="!isEditing">
          <profile-info-card
            title="Profile Information"
            :info="{
              fullName: vendorAccountDetails.first_name + ' ' + vendorAccountDetails.last_name,
              mobile: vendorAccountDetails.mobile,
              email: vendorAccountDetails.email,
              tin: vendorAccountDetails.tin ?? 'NONE',
              company_name: vendorAccountDetails.company_name ?? 'NA',
              location: vendorAccountDetails.address_1 +' ' + vendorAccountDetails.address_2+' ' + vendorAccountDetails.city +' ' + vendorAccountDetails.state,
            }"
            :action="{
              route: 'javascript:;',
              tooltip: 'Edit Profile',
              click: () => { isEditing = true },
            }"
          />
          <button class="btn btn-primary mt-3" @click="isEditing = true">
            Edit Information
          </button>
        </div>
        <div v-else>
          <form @submit.prevent="update_vendor_data">
            <div class="form-group">
              <label for="first_name">First Name</label>
              <input
                id="first_name"
                v-model="vendorAccountDetails.first_name"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="last_name">Last Name</label>
              <input
                id="last_name"
                v-model="vendorAccountDetails.last_name"
                class="form-control"
                required
              />
            </div>
            
            <div class="form-group">
              <label for="mobile">Mobile</label>
              <input
                id="mobile"
                v-model="vendorAccountDetails.mobile"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="vendorAccountDetails.email"
                type="email"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="company_name">Company Name</label>
              <input
                id="company_name"
                v-model="vendorAccountDetails.company_name"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="company_name">Address 1</label>
              <input
                id="address_1"
                v-model="vendorAccountDetails.address_1"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="address_2">Address 2</label>
              <input
                id="address_2"
                v-model="vendorAccountDetails.address_2"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input
                id="city"
                v-model="vendorAccountDetails.city"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="state">State</label>
              <input
                id="state"
                v-model="vendorAccountDetails.state"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="tin">Tin</label>
              <input
                id="tin"
                v-model="vendorAccountDetails.tin"
                class="form-control"
                required
              />
            </div>
            <button type="submit" class="btn btn-success mt-3">
              Save Changes
            </button>
            <button
              type="button"
              class="btn btn-secondary mt-3"
              @click="isEditing = false"
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  name: "ProfileOverview",
  components: {
    ProfileInfoCard,
  },
  data() {
    return {
      isEditing: false, // Toggle for editing mode
      vendorAccountDetails: [],
    };
  },
  computed: {
    firstName() {
      return localStorage.getItem("first_name") || "Welcome";
    },
    lastName() {
      return localStorage.getItem("last_name") || "Here";
    },
    companyName() {
      return localStorage.getItem("company_name") || "Blessed FMS";
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.fetch_vendor_data();
  },
  methods: {
    async fetch_vendor_data() {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_DOMAIN}/api/fetch_account`,
        [],
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.vendorAccountDetails = response.data.data;
    },
    async update_vendor_data() {
      const token = localStorage.getItem("token");
        try {
              const response = await axios.post(
                `${process.env.VUE_APP_BACKEND_DOMAIN}/api/update_vendor_account`,
                this.vendorAccountDetails,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );
              console.log(response);
                Swal.fire({
                  title: "Profile updated successfully!",
                  icon: "success",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton:true,
                  focusConfirm: false,
                });
              this.isEditing = false;
              this.fetch_vendor_data(); 
              return;
            } catch (error) {
              this.fetch_vendor_data(); 
              let errorMessage="";
              if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
              } else {
                errorMessage = "An unexpected error occurred. Please try again.";
              }
              console.error(error);
              return  Swal.fire({
                  title: errorMessage,
                  icon: "error",
                  showCloseButton: true,
                  showCancelButton: true,
                  showConfirmButton:true,
                  focusConfirm: false,
                });
                
             
        }
      },
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

